import { Menu } from "@/interfaces/menu";
import { UserRoles } from "@/interfaces/user";

export const DEFAULT_STATIC_MENU: Menu = [
  {
    title: null,
    roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_CLIENT],
    items: [
      {
        path: "",
        icon: "bx bxs-dashboard",
        title: "Přehled",
        roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_CLIENT],
      },
    ],
  },
  {
    title: "Má domácnost",
    roles: [UserRoles.ROLE_CLIENT],
    items: [
      {
        path: "client-my-devices",
        icon: "bx bxs-component",
        title: "Instalace",
        roles: [UserRoles.ROLE_CLIENT],
        items: [], // TODO: ?
      },
    ],
  },
  {
    title: "Administrace",
    roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE],
    items: [
      {
        path: "users-list",
        icon: "bx bx-user-x",
        title: "Uživatelé",
        roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE],
        items: [],
        dynamicItemsPath: "users",
      },
      {
        path: "devices-list",
        icon: "bx bxs-component",
        title: "Zařízení",
        roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE],
        items: [],
        dynamicItemsPath: "devices",
      },
      {
        path: "admin-sensors-list",
        icon: "bx bxs-tachometer",
        title: "Sensory",
        roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE],
        items: [],
      },
      {
        path: "admin-settings-locale-types-list",
        icon: "bx bx-list-ul",
        title: "Lokální typy",
        roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE],
      },
      {
        path: "changelog",
        icon: "bx bxs-error",
        title: "Changelog",
        roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_EMPLOYEE],
      },
    ],
  },
  // {
  //   title: "MAIN",
  //   roles: [],
  //   items: [
  //     {
  //       title: "Dashboards",
  //       icon: "bx bx-home",
  //       badge: "12",
  //       badgeColor: "bg-warning-transparent",
  //       roles: [],
  //       path: "/crm TODO :P",
  //       items: [
  //         {
  //           path: "/dashboard/crm",
  //           title: "CRM",
  //           roles: [],
  //           icon: "CRM TODO :P",
  //           items: [],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "GENERAL",
  //   roles: [],
  //   items: [
  //     {
  //       path: "/widgets",
  //       title: "Widgets",
  //       icon: "bx bx-gift",
  //       badge: "Hot",
  //       badgeColor: "bg-danger-transparent",
  //       roles: [],
  //       items: [],
  //     },
  //   ],
  // },
  // {
  //   title: "ICONS",
  //   roles: [],
  //   items: [
  //     {
  //       path: "/icons",
  //       icon: "bx bx-store-alt",
  //       title: "Icons",
  //       roles: [],
  //       items: [],
  //     },
  //   ],
  // },
];
