import { AsyncDataOptions } from "nuxt/app";

import FetchFactory from "../../../factory";

interface IHistory {
  lastChanged: string;
  state: string;
}

class HistoryModule extends FetchFactory<IHistory> {
  private RESOURCE = "/device/entity/histories";
  private RESOURCE_FOR_GRAPH = "/device/entity/history-graph";

  /**
   * @param entityId
   * @param dateFrom
   * @param dateTo
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getHistory(
    entityId: number,
    dateFrom: string,
    dateTo: string,
    asyncDataOptions?: AsyncDataOptions<IHistory[]>,
  ) {
    const fetchOptions: FetchOptions<"ld+json"> = {
      params: {
        "lastChanged[after]": dateFrom,
        "lastChanged[before]": dateTo,
        dataForGraph: true,
        entity: entityId,
      },
      watch: [dateFrom, dateTo],
    };

    return useAsyncData(
      "history-" + entityId,
      () => {
        return this.call(
          "GET",
          `${this.RESOURCE}`,
          undefined, // body
          fetchOptions,
        );
      },
      asyncDataOptions,
    );
  }

  /**
   * @param entityId
   * @param dateFrom
   * @param dateTo
   * @param returnType
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getHistoryForGraph(
    entityId: number,
    dateFrom: string,
    dateTo: string,
    returnType: string,
    asyncDataOptions?: AsyncDataOptions<IHistory[]>,
  ) {
    const fetchOptions: FetchOptions<"ld+json"> = {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        returnType: returnType,
        entityId: entityId,
      },
      watch: [dateFrom, dateTo],
    };

    return useAsyncData(
      "history-" + entityId,
      () => {
        return this.call(
          "GET",
          `${this.RESOURCE_FOR_GRAPH}`,
          undefined, // body
          fetchOptions,
        );
      },
      asyncDataOptions,
    );
  }

  /**
   * @param settingIds
   * @param dateFrom
   * @param dateTo
   * @param returnType
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getHistoryForGraphBySettingIds(
    settingIds: array,
    dateFrom: string,
    dateTo: string,
    returnType: string,
    asyncDataOptions?: AsyncDataOptions<IHistory[]>,
  ) {
    const fetchOptions: FetchOptions<"ld+json"> = {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        returnType: returnType,
        "settingIds[]": settingIds,
      },
      watch: [dateFrom, dateTo],
    };

    return useAsyncData(
      "history-" + settingIds.join("-"),
      () => {
        return this.call(
          "GET",
          `${this.RESOURCE_FOR_GRAPH}`,
          undefined, // body
          fetchOptions,
        );
      },
      asyncDataOptions,
    );
  }
}

export default HistoryModule;
