import { AsyncDataOptions } from "#app";
import { ILocaleType } from "@/interfaces/local";

import FetchFactory from "../factory";
import { IDevice } from "@/interfaces/device";

class LocaleTypeModule extends FetchFactory<ILocaleType> {
  public RESOURCE = "/setting/locale-types";
  private RESOURCE_HIERARCHY = "/setting/locale-type/hierarchy";
  public TYPE_CATEGORY = "CATEGORY";
  public TYPE_VALUE = "VALUE";
  public TYPE_BUTTON_ON_OFF = "BUTTON_ON_OFF";
  public TYPE_BUTTON_START = "BUTTON_START";
  public TYPES = [this.TYPE_CATEGORY, this.TYPE_VALUE, this.TYPE_BUTTON_ON_OFF, this.TYPE_BUTTON_START];

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getLocaleTypes(asyncDataOptions?: AsyncDataOptions<ILocaleType[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getLocaleTypesHierarchy(asyncDataOptions?: AsyncDataOptions<ILocaleType[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE_HIERARCHY}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param localeType
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async createLocaleType(localeType: ILocaleType, asyncDataOptions?: AsyncDataOptions<ILocaleType[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}`, localeType);
    }, asyncDataOptions);
  }

  /**
   * @param localeType
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateLocaleType(localeType: ILocaleType, asyncDataOptions?: AsyncDataOptions<ILocaleType[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${localeType.id}`, localeType, fetchOptions);
    }, asyncDataOptions);
  }

  /**
   * @param localeTypeId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async deleteLocaleType(localeTypeId: number, asyncDataOptions?: AsyncDataOptions<IDevice[]>) {
    return useAsyncData(() => {
      return this.call(
        "DELETE",
        `${this.RESOURCE}/${localeTypeId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }
}

export default LocaleTypeModule;
