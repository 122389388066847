import { AsyncDataOptions } from "#app";
import { IUserDevice } from "@/interfaces/device";

import FetchFactory from "../../factory";

class UserDevicesModule extends FetchFactory<IUserDevice> {
  private RESOURCE = "/user/user-devices";

  public ROLE_ADMIN = "ROLE_ADMIN";
  public ROLE_VIEWER = "ROLE_VIEWER";
  public ROLES_FOR_SELECT = [this.ROLE_ADMIN, this.ROLE_VIEWER];

  /**
   * @param deviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getUserDevices(deviceId: string, asyncDataOptions?: AsyncDataOptions<IUserDevice[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}?device=${deviceId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param userDeviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getUserDevice(userDeviceId: string, asyncDataOptions?: AsyncDataOptions<IUserDevice[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}/${userDeviceId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param userDevice
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async createUserDevice(userDevice: IUserDevice, asyncDataOptions?: AsyncDataOptions<IUserDevice[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}`, userDevice);
    }, asyncDataOptions);
  }

  /**
   * @param userDevice
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateUserDevice(userDevice: IUserDevice, asyncDataOptions?: AsyncDataOptions<IUserDevice[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${userDevice.id}`, userDevice, fetchOptions);
    }, asyncDataOptions);
  }

  /**
   * @param userDeviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async deleteUserDevice(userDeviceId: string, asyncDataOptions?: AsyncDataOptions<IUserDevice[]>) {
    return useAsyncData(() => {
      return this.call(
        "DELETE",
        `${this.RESOURCE}/${userDeviceId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }
}

export default UserDevicesModule;
