// [FILE]: repository/factory.ts

// 3rd's
import { $Fetch, FetchOptions } from "ofetch";
import type { UseFetchOptions } from "nuxt/dist/app";
import { defu } from "defu";
import { useUserStore } from "../stores/user";

/*
 The FetchFactory acts as a wrapper around an HTTP client.
 It encapsulates the functionality for making API requests asynchronously
 through the call function, utilizing the provided HTTP client.
*/
class FetchFactory<T> {
  private $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @returns
   */
  async call(method: string, url: string, data?: object, fetchOptions?: FetchOptions<"ld+json">): Promise<T> {
    const accessToken = useCookie("accessToken");
    const refreshToken = useCookie("refreshToken");
    const router = useRouter();

    const defaults: UseFetchOptions<T> = {
      key: url,
      headers: accessToken.value
        ? {
            Authorization: `Bearer ${accessToken.value}`,
            accept: "application/ld+json",
            "Content-Type": "application/ld+json",
          }
        : {},
      onResponse: async ({ response, options }) => {
        if (response.status === 401) {
          if (refreshToken.value !== null && refreshToken.value !== undefined) {
            try {
              const newToken = await doRefreshToken(refreshToken);
              accessToken.value = newToken.token;
              refreshToken.value = newToken.refresh_token;
              options.headers = { Authorization: `Bearer ${accessToken.value}` };
              useFetch(url, options as UseFetchOptions<T>);
              router.go();
            } catch (error) {
              logout();
              console.error("Token refresh failed:", error);
            }
          } else {
            logout();
          }
        }
      },
    };
    const params = defu(
      {
        method,
        body: data,
        ...fetchOptions,
      },
      defaults,
    );
    return this.$fetch<T>(url, params);
  }
}

async function doRefreshToken(refreshToken) {
  const runtimeConfig = useRuntimeConfig();
  const router = useRouter();
  const localeRoute = useLocaleRoute();

  const logout = () => {
    logUserOut();
    router.push(localeRoute("auth-login"));
  };

  const { data, status } = await useFetch<{ access: string }>(runtimeConfig.public.API_BASE_URL + "/auth/refresh", {
    method: "POST",
    body: { refresh_token: refreshToken.value },
  });

  if (status.value === "success") {
    return data.value;
  } else {
    logout();
  }
}

const logout = () => {
  const localeRoute = useLocaleRoute();
  const { logUserOut } = useUserStore();

  logUserOut();
  router.push(localeRoute("auth-login"));
};

export default FetchFactory;
