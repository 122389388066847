<template>
  <v-app>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <!-- here -->
      <NuxtPage />
    </NuxtLayout>
  </v-app>
</template>

<script lang="ts">
import { useUserStore } from "@/stores/user";
import { useRouter, useRoute } from "vue-router";
import { onBeforeMount, watchEffect, defineComponent, computed } from "vue";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const authStore = useUserStore();
    const localePath = useLocalePath();
    const authenticated = computed(() => authStore.authenticated);

    const redirectToLogin = () => {
      if (!authenticated.value && !route.path.includes("reset-password")) {
        router.push(localePath("auth-login"));
      }
    };

    onBeforeMount(redirectToLogin);

    watchEffect(() => {
      redirectToLogin();
    });

    const getImagePath = (imageName: string) => {
      const publicPath = import.meta.env.BASE_URL || "/";
      return `${publicPath}${imageName}`;
    };

    useHead({
      title: "Lettis Platform Admin",
      link: [
        {
          rel: "icon",
          type: "image/png",
          href: getImagePath("images/favicon.ico"),
        },
      ],
    });

    return {};
  },
});
</script>

<style>
.me-5 {
  margin-inline-end: 3rem !important;
}
</style>
