import { default as listUGPilPqEPQMeta } from "/app/pages/admin/sensors/list.vue?macro=true";
import { default as listLQE1pHwLebMeta } from "/app/pages/admin/settings/locale-types/list.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as reset_45password5ABZjlJW61Meta } from "/app/pages/auth/reset-password.vue?macro=true";
import { default as changelogYAEGb3XpZ4Meta } from "/app/pages/changelog.vue?macro=true";
import { default as detail_45_91id_93_45history_45_91settingId_93JJs2VZvX2eMeta } from "/app/pages/client/detail-[id]-history-[settingId].vue?macro=true";
import { default as detail_45_91id_93_45historyFts6ANAwfJMeta } from "/app/pages/client/detail-[id]-history.vue?macro=true";
import { default as detail_45_91id_93YWR03zxSGxMeta } from "/app/pages/client/detail-[id].vue?macro=true";
import { default as my_45devicesWvPIAiKyReMeta } from "/app/pages/client/my-devices.vue?macro=true";
import { default as crmda8b5NiqRJMeta } from "/app/pages/dashboard/crm.vue?macro=true";
import { default as home7lqAefXUDgMeta } from "/app/pages/dashboard/home.vue?macro=true";
import { default as create_45devicep6X4NMUmefMeta } from "/app/pages/devices/create-device.vue?macro=true";
import { default as detail_45_91id_93_45history_45_91settingId_93xI4LJhLdsAMeta } from "/app/pages/devices/detail-[id]-history-[settingId].vue?macro=true";
import { default as detail_45_91id_93_45historyl0pIWtkwmbMeta } from "/app/pages/devices/detail-[id]-history.vue?macro=true";
import { default as detail_45_91id_93ABkKFU6V4WMeta } from "/app/pages/devices/detail-[id].vue?macro=true";
import { default as listx8gS6idC1DMeta } from "/app/pages/devices/list.vue?macro=true";
import { default as update_45_91id_939rgPu276gXMeta } from "/app/pages/devices/update-[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as settingssfrdss5mooMeta } from "/app/pages/profile/settings.vue?macro=true";
import { default as createxEPqsVRhadMeta } from "/app/pages/users/create.vue?macro=true";
import { default as listmddMkOHZkgMeta } from "/app/pages/users/list.vue?macro=true";
import { default as update_45_91id_93nN0kwHXp98Meta } from "/app/pages/users/update-[id].vue?macro=true";
export default [
  {
    name: "admin-sensors-list___cs",
    path: "/admin/sensors/list",
    meta: listUGPilPqEPQMeta || {},
    component: () => import("/app/pages/admin/sensors/list.vue").then(m => m.default || m)
  },
  {
    name: "admin-sensors-list___en",
    path: "/en/admin/sensors/list",
    meta: listUGPilPqEPQMeta || {},
    component: () => import("/app/pages/admin/sensors/list.vue").then(m => m.default || m)
  },
  {
    name: "admin-sensors-list___de",
    path: "/de/admin/sensors/list",
    meta: listUGPilPqEPQMeta || {},
    component: () => import("/app/pages/admin/sensors/list.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-locale-types-list___cs",
    path: "/admin/settings/locale-types/list",
    meta: listLQE1pHwLebMeta || {},
    component: () => import("/app/pages/admin/settings/locale-types/list.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-locale-types-list___en",
    path: "/en/admin/settings/locale-types/list",
    meta: listLQE1pHwLebMeta || {},
    component: () => import("/app/pages/admin/settings/locale-types/list.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-locale-types-list___de",
    path: "/de/admin/settings/locale-types/list",
    meta: listLQE1pHwLebMeta || {},
    component: () => import("/app/pages/admin/settings/locale-types/list.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___cs",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___de",
    path: "/de/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___cs",
    path: "/auth/reset-password",
    meta: reset_45password5ABZjlJW61Meta || {},
    component: () => import("/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    meta: reset_45password5ABZjlJW61Meta || {},
    component: () => import("/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___de",
    path: "/de/auth/reset-password",
    meta: reset_45password5ABZjlJW61Meta || {},
    component: () => import("/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "changelog___cs",
    path: "/changelog",
    meta: changelogYAEGb3XpZ4Meta || {},
    component: () => import("/app/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: "changelog___en",
    path: "/en/changelog",
    meta: changelogYAEGb3XpZ4Meta || {},
    component: () => import("/app/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: "changelog___de",
    path: "/de/changelog",
    meta: changelogYAEGb3XpZ4Meta || {},
    component: () => import("/app/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id-history-settingId___cs",
    path: "/client/detail-:id()-history-:settingId()",
    meta: detail_45_91id_93_45history_45_91settingId_93JJs2VZvX2eMeta || {},
    component: () => import("/app/pages/client/detail-[id]-history-[settingId].vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id-history-settingId___en",
    path: "/en/client/detail-:id()-history-:settingId()",
    meta: detail_45_91id_93_45history_45_91settingId_93JJs2VZvX2eMeta || {},
    component: () => import("/app/pages/client/detail-[id]-history-[settingId].vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id-history-settingId___de",
    path: "/de/client/detail-:id()-history-:settingId()",
    meta: detail_45_91id_93_45history_45_91settingId_93JJs2VZvX2eMeta || {},
    component: () => import("/app/pages/client/detail-[id]-history-[settingId].vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id-history___cs",
    path: "/client/detail-:id()-history",
    meta: detail_45_91id_93_45historyFts6ANAwfJMeta || {},
    component: () => import("/app/pages/client/detail-[id]-history.vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id-history___en",
    path: "/en/client/detail-:id()-history",
    meta: detail_45_91id_93_45historyFts6ANAwfJMeta || {},
    component: () => import("/app/pages/client/detail-[id]-history.vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id-history___de",
    path: "/de/client/detail-:id()-history",
    meta: detail_45_91id_93_45historyFts6ANAwfJMeta || {},
    component: () => import("/app/pages/client/detail-[id]-history.vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id___cs",
    path: "/client/detail-:id()",
    meta: detail_45_91id_93YWR03zxSGxMeta || {},
    component: () => import("/app/pages/client/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id___en",
    path: "/en/client/detail-:id()",
    meta: detail_45_91id_93YWR03zxSGxMeta || {},
    component: () => import("/app/pages/client/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: "client-detail-id___de",
    path: "/de/client/detail-:id()",
    meta: detail_45_91id_93YWR03zxSGxMeta || {},
    component: () => import("/app/pages/client/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: "client-my-devices___cs",
    path: "/client/my-devices",
    meta: my_45devicesWvPIAiKyReMeta || {},
    component: () => import("/app/pages/client/my-devices.vue").then(m => m.default || m)
  },
  {
    name: "client-my-devices___en",
    path: "/en/client/my-devices",
    meta: my_45devicesWvPIAiKyReMeta || {},
    component: () => import("/app/pages/client/my-devices.vue").then(m => m.default || m)
  },
  {
    name: "client-my-devices___de",
    path: "/de/client/my-devices",
    meta: my_45devicesWvPIAiKyReMeta || {},
    component: () => import("/app/pages/client/my-devices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm___cs",
    path: "/dashboard/crm",
    meta: crmda8b5NiqRJMeta || {},
    component: () => import("/app/pages/dashboard/crm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm___en",
    path: "/en/dashboard/crm",
    meta: crmda8b5NiqRJMeta || {},
    component: () => import("/app/pages/dashboard/crm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm___de",
    path: "/de/dashboard/crm",
    meta: crmda8b5NiqRJMeta || {},
    component: () => import("/app/pages/dashboard/crm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-home___cs",
    path: "/dashboard/home",
    meta: home7lqAefXUDgMeta || {},
    component: () => import("/app/pages/dashboard/home.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-home___en",
    path: "/en/dashboard/home",
    meta: home7lqAefXUDgMeta || {},
    component: () => import("/app/pages/dashboard/home.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-home___de",
    path: "/de/dashboard/home",
    meta: home7lqAefXUDgMeta || {},
    component: () => import("/app/pages/dashboard/home.vue").then(m => m.default || m)
  },
  {
    name: "devices-create-device___cs",
    path: "/devices/create-device",
    meta: create_45devicep6X4NMUmefMeta || {},
    component: () => import("/app/pages/devices/create-device.vue").then(m => m.default || m)
  },
  {
    name: "devices-create-device___en",
    path: "/en/devices/create-device",
    meta: create_45devicep6X4NMUmefMeta || {},
    component: () => import("/app/pages/devices/create-device.vue").then(m => m.default || m)
  },
  {
    name: "devices-create-device___de",
    path: "/de/devices/create-device",
    meta: create_45devicep6X4NMUmefMeta || {},
    component: () => import("/app/pages/devices/create-device.vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id-history-settingId___cs",
    path: "/devices/detail-:id()-history-:settingId()",
    meta: detail_45_91id_93_45history_45_91settingId_93xI4LJhLdsAMeta || {},
    component: () => import("/app/pages/devices/detail-[id]-history-[settingId].vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id-history-settingId___en",
    path: "/en/devices/detail-:id()-history-:settingId()",
    meta: detail_45_91id_93_45history_45_91settingId_93xI4LJhLdsAMeta || {},
    component: () => import("/app/pages/devices/detail-[id]-history-[settingId].vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id-history-settingId___de",
    path: "/de/devices/detail-:id()-history-:settingId()",
    meta: detail_45_91id_93_45history_45_91settingId_93xI4LJhLdsAMeta || {},
    component: () => import("/app/pages/devices/detail-[id]-history-[settingId].vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id-history___cs",
    path: "/devices/detail-:id()-history",
    meta: detail_45_91id_93_45historyl0pIWtkwmbMeta || {},
    component: () => import("/app/pages/devices/detail-[id]-history.vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id-history___en",
    path: "/en/devices/detail-:id()-history",
    meta: detail_45_91id_93_45historyl0pIWtkwmbMeta || {},
    component: () => import("/app/pages/devices/detail-[id]-history.vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id-history___de",
    path: "/de/devices/detail-:id()-history",
    meta: detail_45_91id_93_45historyl0pIWtkwmbMeta || {},
    component: () => import("/app/pages/devices/detail-[id]-history.vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id___cs",
    path: "/devices/detail-:id()",
    meta: detail_45_91id_93ABkKFU6V4WMeta || {},
    component: () => import("/app/pages/devices/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id___en",
    path: "/en/devices/detail-:id()",
    meta: detail_45_91id_93ABkKFU6V4WMeta || {},
    component: () => import("/app/pages/devices/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: "devices-detail-id___de",
    path: "/de/devices/detail-:id()",
    meta: detail_45_91id_93ABkKFU6V4WMeta || {},
    component: () => import("/app/pages/devices/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: "devices-list___cs",
    path: "/devices/list",
    meta: listx8gS6idC1DMeta || {},
    component: () => import("/app/pages/devices/list.vue").then(m => m.default || m)
  },
  {
    name: "devices-list___en",
    path: "/en/devices/list",
    meta: listx8gS6idC1DMeta || {},
    component: () => import("/app/pages/devices/list.vue").then(m => m.default || m)
  },
  {
    name: "devices-list___de",
    path: "/de/devices/list",
    meta: listx8gS6idC1DMeta || {},
    component: () => import("/app/pages/devices/list.vue").then(m => m.default || m)
  },
  {
    name: "devices-update-id___cs",
    path: "/devices/update-:id()",
    meta: update_45_91id_939rgPu276gXMeta || {},
    component: () => import("/app/pages/devices/update-[id].vue").then(m => m.default || m)
  },
  {
    name: "devices-update-id___en",
    path: "/en/devices/update-:id()",
    meta: update_45_91id_939rgPu276gXMeta || {},
    component: () => import("/app/pages/devices/update-[id].vue").then(m => m.default || m)
  },
  {
    name: "devices-update-id___de",
    path: "/de/devices/update-:id()",
    meta: update_45_91id_939rgPu276gXMeta || {},
    component: () => import("/app/pages/devices/update-[id].vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___cs",
    path: "/profile/settings",
    meta: settingssfrdss5mooMeta || {},
    component: () => import("/app/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___en",
    path: "/en/profile/settings",
    meta: settingssfrdss5mooMeta || {},
    component: () => import("/app/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___de",
    path: "/de/profile/settings",
    meta: settingssfrdss5mooMeta || {},
    component: () => import("/app/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "users-create___cs",
    path: "/users/create",
    meta: createxEPqsVRhadMeta || {},
    component: () => import("/app/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users-create___en",
    path: "/en/users/create",
    meta: createxEPqsVRhadMeta || {},
    component: () => import("/app/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users-create___de",
    path: "/de/users/create",
    meta: createxEPqsVRhadMeta || {},
    component: () => import("/app/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users-list___cs",
    path: "/users/list",
    meta: listmddMkOHZkgMeta || {},
    component: () => import("/app/pages/users/list.vue").then(m => m.default || m)
  },
  {
    name: "users-list___en",
    path: "/en/users/list",
    meta: listmddMkOHZkgMeta || {},
    component: () => import("/app/pages/users/list.vue").then(m => m.default || m)
  },
  {
    name: "users-list___de",
    path: "/de/users/list",
    meta: listmddMkOHZkgMeta || {},
    component: () => import("/app/pages/users/list.vue").then(m => m.default || m)
  },
  {
    name: "users-update-id___cs",
    path: "/users/update-:id()",
    meta: update_45_91id_93nN0kwHXp98Meta || {},
    component: () => import("/app/pages/users/update-[id].vue").then(m => m.default || m)
  },
  {
    name: "users-update-id___en",
    path: "/en/users/update-:id()",
    meta: update_45_91id_93nN0kwHXp98Meta || {},
    component: () => import("/app/pages/users/update-[id].vue").then(m => m.default || m)
  },
  {
    name: "users-update-id___de",
    path: "/de/users/update-:id()",
    meta: update_45_91id_93nN0kwHXp98Meta || {},
    component: () => import("/app/pages/users/update-[id].vue").then(m => m.default || m)
  }
]