import axios, { AxiosInstance } from "axios";
import { useRuntimeConfig } from "nuxt/app";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const instance: AxiosInstance = axios.create({
    baseURL: `${config.public.API_BASE_URL}/`,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const setAuthTokenHeader = (token: string | null) => {
    let t: string | null = `Bearer ${token}`;
    if (token === null) t = null;
    instance.defaults.headers.common["Authorization"] = t;
  };

  return {
    provide: {
      axios: instance,
      setAuthTokenHeader,
    },
  };
});
