import { AsyncDataOptions } from "#app";
import { IDevice } from "@/interfaces/device";

import FetchFactory from "../../factory";

class DevicesModule extends FetchFactory<IDevice> {
  private RESOURCE = "/devices";
  private RESOURCE_SUMMARY = "summary";
  private RESOURCE_FORECAST_CURRENT = "forecast-current";

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getDevices(asyncDataOptions?: AsyncDataOptions<IDevice[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param deviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getDevice(deviceId: string, asyncDataOptions?: AsyncDataOptions<IDevice[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}/${deviceId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param device
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async createDevice(device: IDevice, asyncDataOptions?: AsyncDataOptions<IDevice[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}`, device);
    }, asyncDataOptions);
  }

  /**
   * @param device
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateDevice(device: IDevice, asyncDataOptions?: AsyncDataOptions<IDevice[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${device.id}`, device, fetchOptions);
    }, asyncDataOptions);
  }

  /**
   * @param deviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async deleteDevice(deviceId: string, asyncDataOptions?: AsyncDataOptions<IDevice[]>) {
    return useAsyncData(() => {
      return this.call(
        "DELETE",
        `${this.RESOURCE}/${deviceId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param deviceId
   * @param settingIds
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getDeviceSummary(deviceId: string, settingIds: array, asyncDataOptions?: AsyncDataOptions<IDevice[]>) {
    const fetchOptions: FetchOptions<"ld+json"> = {
      params: {
        "settingIds[]": settingIds,
      },
    };

    return useAsyncData(
      "summary-" + deviceId + "-" + (typeof settingIds !== "undefined" ? settingIds.join("-") : null),
      () => {
        return this.call(
          "GET",
          `${this.RESOURCE}/${deviceId}/${this.RESOURCE_SUMMARY}`,
          undefined, // body
          fetchOptions,
        );
      },
      asyncDataOptions,
    );
  }

  /**
   * @param deviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getForecastCurrent(deviceId: string, asyncDataOptions?: AsyncDataOptions<IForecastCurrent[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}/${deviceId}/${this.RESOURCE_FORECAST_CURRENT}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }
}

export default DevicesModule;
