import { useLocaleRoute } from "../.nuxt/imports";

export default defineNuxtRouteMiddleware((to) => {
  const { authenticated } = storeToRefs(useUserStore()); // make authenticated state reactive
  const token = useCookie("accessToken"); // get token from cookies
  const localeRoute = useLocaleRoute();
  if (token.value) {
    // check if value exists
    authenticated.value = true; // update the state to authenticated
  }

  // if token exists and url is /login redirect to homepage
  if (token.value && to && to.name.startsWith("auth-login")) {
    return navigateTo(localeRoute("home"));
  }

  // if token doesn't exist redirect to log in
  if (!token.value && (!to || (to && !to.name.startsWith("auth-login")))) {
    return navigateTo(localeRoute("auth-login"));
  }
});
