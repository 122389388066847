import { AsyncDataOptions } from "#app";
import { IRegistrationRequest } from "@/interfaces/registrationRequest";

import FetchFactory from "../../factory";

class RegistrationRequestModule extends FetchFactory<IRegistrationRequest> {
  private RESOURCE = "/sensor/registration-requests";

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getRegistrationRequests(asyncDataOptions?: AsyncDataOptions<IRegistrationRequest[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }
}

export default RegistrationRequestModule;
