import { IDevice } from "./device";

export enum UserRoles {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_CLIENT = "ROLE_CLIENT",
  ROLE_DEMO = "ROLE_DEMO",
  ROLE_EMPLOYEE = "ROLE_EMPLOYEE",
}

export interface Distributor {} // TODO:

export interface IUser {
  "@id": string;
  "@context": string;
  "@type": string;
  id: string;
  email: string;
  plainPassword: string;
  password: string;
  userDevices: IDevice[];
  userDistributors: Distributor[];
  userIdentifier: string;
  firstName: string;
  lastName: string;
  roles: UserRoles[];
}
