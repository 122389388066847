import { AsyncDataOptions } from "#app";
import { ISensor } from "@/interfaces/sensor";

import FetchFactory from "../../factory";

class SensorModule extends FetchFactory<IDevice> {
  private RESOURCE = "/sensors";

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getSensors(asyncDataOptions?: AsyncDataOptions<ISensor[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param sensorId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getSensor(sensorId: string, asyncDataOptions?: AsyncDataOptions<ISensor[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}/${sensorId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param sensor
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async createSensor(sensor: ISensor, asyncDataOptions?: AsyncDataOptions<ISensor[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}`, sensor);
    }, asyncDataOptions);
  }

  /**
   * @param sensor
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateSensor(sensor: ISensor, asyncDataOptions?: AsyncDataOptions<ISensor[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${sensor.id}`, sensor, fetchOptions);
    }, asyncDataOptions);
  }

  /**
   * @param sensorId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async deleteSensor(sensorId: string, asyncDataOptions?: AsyncDataOptions<ISensor[]>) {
    return useAsyncData(() => {
      return this.call(
        "DELETE",
        `${this.RESOURCE}/${sensorId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }
}

export default SensorModule;
