<template lang="html">
  <PageHeader :prop-data="dataToPass" />

  <!-- Start::row-1 -->
  <div class="row">
    <div class="col-xl-12">
      <div class="card custom-card">
        <div class="card-header">
          <div class="card-title">Soupis změn</div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Popis změny</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>7.5.2024</td>
                  <td>Fix chyb pri vytvareni</td>
                </tr>
                <tr>
                  <td>6.5.2024</td>
                  <td>
                    Editace typu<br />
                    Editace parametru<br />
                    Editace zobrazovacich bloku
                  </td>
                </tr>
                <tr>
                  <td>30.4.2024</td>
                  <td>
                    Fix google map<br />
                    Ošetření hydra erroru
                  </td>
                </tr>
                <tr>
                  <td>29.4.2024</td>
                  <td>
                    Upraveno nastavení polohy<br />
                    Integrována automatická předpověd počasí<br />
                    Upraven visuál detailu zařízení
                  </td>
                </tr>
                <tr>
                  <td>28.4.2024</td>
                  <td>Rozšíření detailu zařízení - aktuální hodnoty systému</td>
                </tr>
                <tr>
                  <td>28.4.2024</td>
                  <td>Rozšíření detailu zařízení</td>
                </tr>
                <tr>
                  <td>27.4.2024</td>
                  <td>Zafixován9 breadcrumbs, navigace a detailu zařízení</td>
                </tr>
                <tr>
                  <td>26.4.2024</td>
                  <td>Donastaven graf pro zobrazovani v lokalnim case</td>
                </tr>
                <tr>
                  <td>26.4.2024</td>
                  <td>Pridano prirazovani uzivatelu k jednotlivym zarizenim</td>
                </tr>
                <tr>
                  <td>26.4.2024</td>
                  <td>Doreseni pridani, upravy, smazani uzivatelu, zarizeni, konfigurace entit</td>
                </tr>
                <tr>
                  <td>25.4.2024</td>
                  <td>Upgrade celeho systemu na Symfony 7 core</td>
                </tr>
                <tr>
                  <td>25.4.2024</td>
                  <td>Implementace priprav na multijazycnost</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::row-1 -->
</template>
<script lang="ts">
import PageHeader from "~/components/common/pageheader-main.vue";
import auth from "@/middleware/auth";
import { definePageMeta } from "../.nuxt/imports";

export default {
  components: {
    PageHeader,
  },
  setup() {
    definePageMeta({
      middleware: [auth],
      layout: "default",
    });
  },
  data() {
    return {
      dataToPass: {
        current: "Changelog",
      },
    };
  },
};
</script>

<style scoped></style>
