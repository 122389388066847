import { AsyncDataOptions } from "#app";
import { IParameter } from "@/interfaces/parametr";

import FetchFactory from "../../../factory";

class ParameterModule extends FetchFactory<IEntity> {
  private RESOURCE = "/device/parameters";

  /**
   * @param parameter
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateParameter(parameter: IParameter, asyncDataOptions?: AsyncDataOptions<IParameter[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${parameter.id}`, parameter, fetchOptions);
    }, asyncDataOptions);
  }
}

export default ParameterModule;
