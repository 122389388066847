// 3rd's
import { FetchOptions } from "ofetch";
import { AsyncDataOptions } from "nuxt/app";

// locals
import FetchFactory from "../factory";
import { UserRoles } from "@/interfaces/user";

interface IProfile {
  id: number;
  email: string;
  role: UserRoles[];
  rating: {
    rate: number;
    count: number;
  };
}

class ProfileModule extends FetchFactory<IProfile> {
  private RESOURCE = "/profile.jsonld";

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getProfile(asyncDataOptions?: AsyncDataOptions<IProfile[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          accept: "application/ld+json",
          "Content-Type": "application/ld+json",
        },
      };
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
        fetchOptions,
      );
    }, asyncDataOptions);
  }
}

export default ProfileModule;
