<template>
  <ClientOnly>
    <VueApexChartsColumn
      :ref="reference || 'apexchart'"
      :width="width || '100%'"
      :height="height"
      type="bar"
      :options="options"
      :series="series"
    />
  </ClientOnly>
</template>
<script>
import VueApexChartsColumn from "vue3-apexcharts";
import { ref } from "vue";
import { useDayjs } from "#dayjs";
const dayjs = useDayjs();

export default {
  components: {
    VueApexChartsColumn,
  },
  props: ["width", "height", "series", "reference", "unit-of-measurement", "stacked"],
  setup(props) {
    const options = ref({
      chart: {
        type: "bar",
        height: 320,
        stacked: props.stacked,
        toolbar: {
          export: {
            csv: {
              headerCategory: "Date",
              columnDelimiter: ",",
              // headerValue: "value",
              dateFormatter(timestamp) {
                var date = dayjs(timestamp);
                var format = "DD.MM.YYYY";
                return date.format(format);
              },
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          endingShape: "rounded",
        },
      },
      grid: {
        borderColor: "#f2f5f7",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        title: {
          text: props.unitOfMeasurement,
          style: {
            color: "#8c9097",
          },
        },
        labels: {
          formatter: function (val) {
            return (val / 1).toFixed(2);
          },
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (val / 1).toFixed(2) + " " + props.unitOfMeasurement;
          },
        },
      },
    });

    return {
      options,
    };
  },
  mounted() {},
};
</script>
