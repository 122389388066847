import { defineStore } from "pinia";
import { ref } from "vue";
import { ROLES_ALLOWED_FOR_LOGIN } from "../constants/userConstants";
import { AuthResponse } from "@/interfaces/auth";
import { IUser } from "@/interfaces/user";

interface UserPayloadInterface {
  id: number;
  email: string;
  password: string;
  token: string;
  refreshToken: string;
}

export const useUserStore = defineStore("user", () => {
  const authenticated = ref(false);
  const loading = ref(false);
  const user = ref<IUser | null>(null);

  watch(authenticated, async () => {
    authenticated.value && (await getUserProfile());
  });

  const getUserProfile = async (): Promise<void> => {
    const { $axios, $setAuthTokenHeader } = useNuxtApp();
    const token = useCookie("accessToken");

    try {
      $setAuthTokenHeader(token.value as string);
      const { data: userRes } = await $axios.get<IUser>("profile.jsonld", {
        headers: {
          accept: "application/ld+json",
          "Content-Type": "application/ld+json",
        },
      });
      user.value = userRes;
    } catch (error) {
      console.error(error);
    }
  };

  const authUser = async ({ email, password }: Partial<UserPayloadInterface>) => {
    const { $axios } = useNuxtApp();
    const token = useCookie("accessToken");
    const refreshToken = useCookie("refreshToken");
    loading.value = true;

    try {
      const { data: authRes } = await $axios.post<AuthResponse>("auth", { email, password });
      if (authRes) {
        const isUserAllowedToLogin = authRes.data.roles.some((r) => ROLES_ALLOWED_FOR_LOGIN.includes(r));
        if (!isUserAllowedToLogin) authenticated.value = false;
        else {
          token.value = authRes.token;
          refreshToken.value = authRes.refresh_token;
          authenticated.value = true;
        }
        return { authenticated: authenticated.value };
      }
      authenticated.value = false;
      token.value = null;
      refreshToken.value = null;
      return { authenticated: false };
    } catch (error) {
      console.error(error);
      authenticated.value = false;
      token.value = null;
      refreshToken.value = null;
      return { authenticated: false };
    } finally {
      loading.value = false;
    }
  };

  const logUserOut = () => {
    const token = useCookie("accessToken");
    const refreshToken = useCookie("refreshToken");
    authenticated.value = false;
    token.value = null;
    refreshToken.value = null;
    user.value = null;
  };

  const resetPassword = async (user: Partial<IUser>) => {
    const { $axios } = useNuxtApp();

    try {
      await $axios.post("user/reset-password", user); // TODO: Test
    } catch (error) {
      console.error(error);
    }
  };

  return {
    authenticated,
    loading,
    user,
    authUser,
    logUserOut,
    resetPassword,
  };
});
