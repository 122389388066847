<template>
  <ClientOnly>
    <VueApexChartsLine
      :ref="reference || 'apexchart'"
      :width="width || '100%'"
      :height="height"
      type="area"
      :options="options"
      :series="series"
    />
  </ClientOnly>
</template>
<script>
import VueApexChartsLine from "vue3-apexcharts";
import { ref } from "vue";
import { useDayjs } from "#dayjs";
const dayjs = useDayjs();

export default {
  components: {
    VueApexChartsLine,
  },
  props: ["width", "height", "series", "reference", "unit-of-measurement", "stacked"],
  setup(props) {
    const options = ref({
      chart: {
        type: "line",
        stacked: props.stacked,
        height: 320,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
          export: {
            csv: {
              headerCategory: "Date,Time",
              columnDelimiter: ",",
              // headerValue: "value",
              dateFormatter(timestamp) {
                var date = dayjs(timestamp);
                var format = "DD.MM.YYYY,HH:mm";
                return date.format(format);
              },
            },
          },
        },
        events: {
          beforeZoom: function (ctx) {
            // we need to clear the range as we only need it on the iniital load.
            ctx.w.config.xaxis.range = undefined;
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: "smooth",
      },
      // colors: ["#845adf"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      grid: {
        borderColor: "#f2f5f7",
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val / 1).toFixed(2);
          },
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
        title: {
          text: props.unitOfMeasurement,
          fontSize: "13px",
          fontWeight: "bold",
          style: {
            color: "#8c9097",
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: true,
          datetimeUTC: false,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      tooltip: {
        shared: false,
        followCursor: true,
        y: {
          formatter: function (val) {
            return (val / 1).toFixed(2) + " " + props.unitOfMeasurement;
          },
        },
        x: {
          format: "dd.MM HH:mm",
        },
      },
      noData: {
        text: "Ve vybraném časovém úseku nejsou žádná data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
    });

    return {
      options,
    };
  },
  mounted() {},
};
</script>
