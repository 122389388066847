import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { Popover } from "bootstrap/dist/js/bootstrap.js";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world-merc.js";
import "jsvectormap/dist/maps/world.js";
// import "jsvectormap/dist/css/jsvectormap.css";
import VueApexChartsLine from "../components/UI/apexcharts/apexcharts-line.vue";
import VueApexChartsColumn from "../components/UI/apexcharts/apexcharts-column.vue";
import VueApexChartsDonut from "../components/UI/apexcharts/apexcharts-donut.vue";
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

const customColors = {
  dark: false,
  colors: {
    primary: "#df5a5a",
    secondary: "#23b7e5",
  },
};

export default defineNuxtPlugin((nuxt) => {
  const vuetify = createVuetify({
    ssr: false,
    components,
    directives,
    theme: {
      defaultTheme: "customColors",
      themes: {
        customColors,
      },
    },
  });
  nuxt.vueApp.use(vuetify);
  nuxt.vueApp.use(PerfectScrollbar);
  nuxt.vueApp.component("jsVectorMap", jsVectorMap);
  nuxt.vueApp.component("apexchart-line", VueApexChartsLine);
  nuxt.vueApp.component("apexchart-column", VueApexChartsColumn);
  nuxt.vueApp.component("apexchart-donut", VueApexChartsDonut);
  nuxt.vueApp.use(Vue3ColorPicker);
  nuxt.vueApp.provide("Popover", Popover);
  nuxt.vueApp.component("EasyDataTable", Vue3EasyDataTable);
  nuxt.vueApp.component("Datepicker", Datepicker);
  nuxt.vueApp.component("VueMultiselect", VueMultiselect);
});
