import { useNuxtApp } from "nuxt/app";
import { DEFAULT_STATIC_MENU } from "@/constants/menu";
import { Menu, MenuItem } from "@/interfaces/menu";
import { defineStore } from "pinia";

export const useMenuStore = defineStore("menu", () => {
  const menu = ref<Menu>(DEFAULT_STATIC_MENU);
  const opened = ref<string[]>([]);

  const loadOpenedState = () => {
    const savedOpened = localStorage.getItem("openedMenuItems");
    if (savedOpened) {
      opened.value = JSON.parse(savedOpened);
    }
  };

  watch(
    () => opened.value,
    (newVal) => {
      localStorage.setItem("openedMenuItems", JSON.stringify(newVal));
    },
    { deep: true },
  );

  const getDynamicItems = async () => {
    const { $axios } = useNuxtApp();

    menu.value.forEach((section, sectionIndex) => {
      section.items.forEach(async (item, itemIndex) => {
        if (!item.dynamicItemsPath) return;
        const path: string | undefined = item.path?.split("-list")[0];
        const { data: items } = await $axios.get(`${item.dynamicItemsPath}`);
        const formatedItems: MenuItem[] = items["hydra:member"].map(
          (e: { name: string; lastName: string; firstName: string; id: string }) => ({
            id: e.id,
            title: e.name || `${e.firstName} ${e.lastName}`,
            path,
          }),
        );

        menu.value[sectionIndex].items[itemIndex].items = formatedItems;
      });
    });
  };

  loadOpenedState();

  return {
    menu,
    opened,
    getDynamicItems,
  };
});
