// [File]: plugins/api.ts

// 3rd's
import { $fetch, FetchOptions } from "ofetch";

// locals
import AuthModule from "../repository/modules/auth";
import ProfileModule from "../repository/modules/profile";
import DevicesModule from "../repository/modules/device/devices";
import SettingsModule from "../repository/modules/device/settings";
import HistoryModule from "../repository/modules/device/entity/history";
import UsersModule from "../repository/modules/users";
import EntitiesModule from "../repository/modules/device/entity/entity";
import UserDevicesModule from "../repository/modules/user/userDevices";
import LocaleTypeModule from "../repository/setting/localeType";
import ParameterModule from "../repository/modules/device/entity/parameter";
import SensorModule from "@/repository/modules/sensor/sensor";
import RegistrationRequestModule from "@/repository/modules/sensor/registrationRequest";

interface IApiInstance {
  auth: AuthModule;
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const fetchOptions: FetchOptions = {
    baseURL: config.public.API_BASE_URL,
  };

  // Create a new instance of $fecther with custom option
  const apiFecther = $fetch.create(fetchOptions);

  // An object containing all repositories we need to expose
  const modules: IApiInstance = {
    auth: new AuthModule(apiFecther),
    devices: new DevicesModule(apiFecther),
    deviceParameter: new ParameterModule(apiFecther),
    entities: new EntitiesModule(apiFecther),
    history: new HistoryModule(apiFecther),
    profile: new ProfileModule(apiFecther),
    registrationRequest: new RegistrationRequestModule(apiFecther),
    sensors: new SensorModule(apiFecther),
    settingLocaleType: new LocaleTypeModule(apiFecther),
    settings: new SettingsModule(apiFecther),
    userDevices: new UserDevicesModule(apiFecther),
    users: new UsersModule(apiFecther),
  };

  return {
    provide: {
      api: modules,
      devices: modules,
      deviceParameter: modules,
      entities: modules,
      history: modules,
      profile: modules,
      registrationRequest: modules,
      sensors: modules,
      settingLocaleType: modules,
      settings: modules,
      userDevices: modules,
      users: modules,
    },
  };
});
