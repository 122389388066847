<template>
  <ClientOnly>
    <VueApexChartsColumn
      :ref="reference || 'apexchart'"
      :width="width || '100%'"
      :height="height"
      type="donut"
      :options="options"
      :series="series"
    />
  </ClientOnly>
</template>
<script>
import VueApexChartsColumn from "vue3-apexcharts";
import { ref } from "vue";
import { useDayjs } from "#dayjs";
const dayjs = useDayjs();

export default {
  components: {
    VueApexChartsColumn,
  },
  props: ["width", "height", "series", "reference", "unit-of-measurement", "labels"],
  setup(props) {
    const options = ref({
      chart: {
        type: "donut",
        height: 320,
      },
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
        },
      },
      labels: props.labels,
    });

    return {
      options,
    };
  },
  mounted() {},
};
</script>
