import { AsyncDataOptions } from "#app";
import { IEntity } from "@/interfaces/entity";

import FetchFactory from "../../../factory";

class EntitiesModule extends FetchFactory<IEntity> {
  private RESOURCE = "/device/entities";

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getEntities(asyncDataOptions?: AsyncDataOptions<IEntity[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param deviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getEntitiesForDevice(deviceId: number, asyncDataOptions?: AsyncDataOptions<IEntity[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}?device=${deviceId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param entityId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getEntity(entityId: string, asyncDataOptions?: AsyncDataOptions<IEntity[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}/${entityId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param entity
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async createEntity(entity: IEntity, asyncDataOptions?: AsyncDataOptions<IEntity[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}`, entity);
    }, asyncDataOptions);
  }

  /**
   * @param entity
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateEntity(entity: IEntity, asyncDataOptions?: AsyncDataOptions<IEntity[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${entity.id}`, entity, fetchOptions);
    }, asyncDataOptions);
  }

  /**
   * @param entityId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async deleteEntity(entityId: string, asyncDataOptions?: AsyncDataOptions<IEntity[]>) {
    return useAsyncData(() => {
      return this.call(
        "DELETE",
        `${this.RESOURCE}/${entityId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }
}

export default EntitiesModule;
