import { AsyncDataOptions } from "#app";
import { IUser } from "@/interfaces/user";
import FetchFactory from "../factory";

class UsersModule extends FetchFactory<IUser> {
  private RESOURCE = "/users";
  private RESOURCE_RESET_PASSWORD = "/users/reset-password";

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getUsers(asyncDataOptions?: AsyncDataOptions<IUser[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param userId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getUser(userId: string, asyncDataOptions?: AsyncDataOptions<IUser[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}/${userId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param user
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateUser(user: IUser, asyncDataOptions?: AsyncDataOptions<IUser[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${user.id}`, user, fetchOptions);
    }, asyncDataOptions);
  }

  /**
   * @param user
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async resetPassword(user: IUser, asyncDataOptions?: AsyncDataOptions<IUser[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE_RESET_PASSWORD}`, user);
    }, asyncDataOptions);
  }

  /**
   * @param user
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async createUser(user: IUser, asyncDataOptions?: AsyncDataOptions<IUser[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}`, user);
    }, asyncDataOptions);
  }

  /**
   * @param userId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async deleteUser(userId: string, asyncDataOptions?: AsyncDataOptions<IUser[]>) {
    return useAsyncData(() => {
      return this.call(
        "DELETE",
        `${this.RESOURCE}/${userId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }
}

export default UsersModule;
