import { AsyncDataOptions } from "#app";
import { ISetting } from "@/interfaces/device";
import FetchFactory from "../../factory";

class SettingsModule extends FetchFactory<ISetting> {
  private RESOURCE = "/device/settings";

  /**
   * @param deviceId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getSettings(deviceId: string, asyncDataOptions?: AsyncDataOptions<ISetting[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}?device=${deviceId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param settingId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async getSetting(settingId: string, asyncDataOptions?: AsyncDataOptions<ISetting[]>) {
    return useAsyncData(() => {
      return this.call(
        "GET",
        `${this.RESOURCE}/${settingId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param setting
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async createSetting(setting: ISetting, asyncDataOptions?: AsyncDataOptions<ISetting[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}`, setting);
    }, asyncDataOptions);
  }

  /**
   * @param setting
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async updateSetting(setting: ISetting, asyncDataOptions?: AsyncDataOptions<ISetting[]>) {
    return useAsyncData(() => {
      const fetchOptions: FetchOptions<"ld+json"> = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };
      return this.call("PATCH", `${this.RESOURCE}/${setting.id}`, setting, fetchOptions);
    }, asyncDataOptions);
  }

  /**
   * @param settingId
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async deleteSetting(settingId: string, asyncDataOptions?: AsyncDataOptions<ISetting[]>) {
    return useAsyncData(() => {
      return this.call(
        "DELETE",
        `${this.RESOURCE}/${settingId}`,
        undefined, // body
      );
    }, asyncDataOptions);
  }

  /**
   * @param settingId
   * @param actionBody
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  async runSetting(settingId: number, actionBody: object, asyncDataOptions?: AsyncDataOptions<ISetting[]>) {
    return useAsyncData(() => {
      return this.call("POST", `${this.RESOURCE}/${settingId}/run`, actionBody);
    }, asyncDataOptions);
  }
}

export default SettingsModule;
